import { Role } from "src/backend";

export const UserType = {
    LENDER: 'lender',
    APPLICANT: 'applicant',
    ANONYMOUS: 'anonymous'
} as const;

export enum Permission {
    CrudLoan = 'crud_loan',
    SwitchLoanView = 'switch_loan_view',
    MoveElements = 'move_elements',
    PinFormElements = 'pin_form_elements',
    ChangePackageViewType = 'change_package_view_type',
    CreateFormElements = 'create_form_elements',
    ChangeFormElementPriority = 'change_form_element_priority',
    FilterLoans = 'filter_loans',
    InviteBorrowersToLoan = 'invite_borrowers_to_loan',
    SeeFormElementAssignment = 'see_form_element_assignment',
    SeePendingInvites = 'see_pending_invites',
    CanSeeSourcesAndUsesButton = 'can_see_sources_and_uses_button',
    ChangeLoanPhase = 'change_loan_phase',
    SeeDriversLicense = 'see_drivers_license',
    EditFormElementTitle = 'edit_form_element_title',
    SeeBorrowersCreditScore = 'can_see_borrower_credit_score',
    ChangeLoanDetails = 'change_loan_details',
    SeeBorrowersTasks = 'see_borrowers_tasks',
    CrudBorrower = 'crud_borrower',
    UpdateBorrower = 'update_borrower',
    DeleteFormElements = 'delete_form_elements',
    AssignRoles = 'assign_roles',
    CrudAssociate = 'crud_associate',
    CrudLabels = 'crud_labels',
    CrudKeyContacts = 'crud_key_contacts',
    CrudUnderwriter = 'crud_underwriter',
    CrudContacts = 'crud_contacts',
    GlobalCommunications = 'global_communications',
    LendingTeamCommunication = 'lending_team_communication',
    BorrowerTeamCommunication = 'borrower_team_communication',
    BorrowerTeamPrivateCommunication = 'borrower_team_private_communication',
    AcceptDocuments = 'accept_documents',
    SignDocuments = 'sign_documents',
    FillDocuments = 'fill_documents',
    AskQuestions = 'ask_questions',
    AddNeedItems = 'add_need_items',
    AddMeToLoan = 'add_me_to_loan',
    ViewAllLoanHeaderInformation = 'view_all_loan_header_information',
    viewShoeBoxItems = 'view_shoe_box_items',
    GlobalLendingView = 'global_lending_view',
    ViewBorrowerProgress = 'view_borrower_progress',
    ViewUserProgress = 'view_user_progress',
    ViewLoanProgress = 'view_loan_progress',
    ViewLoanDetails = 'view_loan_details',
    ViewUserTasks = 'view_user_tasks',
    ViewUserDocuments = 'view_user_documents',
    CrudReports = 'crud_reports',
    ArchiveLoanView = 'archive_loan_view',
    ArchiveLoanEdit = 'archive_loan_edit',
    LoanPackageCreation = 'loan_package_creation',
    LoginAsABorrower = 'login_as_borrower',
    LoginAsAUser = 'login_as_a_user',
    Search = 'search',
    CrudDocuments = 'crud_documents',
    ViewCollateral = 'view_collateral',
    DeleteCollateral = 'delete_collateral',
    EditCollateral = 'edit_collateral',
    ScheduleClearRiskReport = 'schedule_clear_risk_report',
}

export const RolePermission: Record<Role, Permission[]> = {
    [Role.LEAD_LENDER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.AddMeToLoan
        ),
    ],
    [Role.MANAGER_LENDER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.AddMeToLoan
        ),
    ],
    [Role.ASSOCIATE_LENDER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.ScheduleClearRiskReport &&
                permission !== Permission.BorrowerTeamPrivateCommunication &&
                permission !== Permission.ChangeLoanPhase &&
                permission !== Permission.AddMeToLoan &&
                permission !== Permission.SeeBorrowersTasks &&
                permission !== Permission.GlobalLendingView &&
                permission !== Permission.ArchiveLoanEdit &&
                permission !== Permission.DeleteCollateral
        ),
    ],
    [Role.LENDER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.BorrowerTeamPrivateCommunication &&
                permission !== Permission.ScheduleClearRiskReport &&
                permission !== Permission.ChangeLoanPhase &&
                permission !== Permission.AddMeToLoan &&
                permission !== Permission.GlobalLendingView &&
                permission !== Permission.ArchiveLoanEdit &&
                permission !== Permission.DeleteCollateral
        ),
    ],
    [Role.UNDERWRITER_LENDER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.BorrowerTeamPrivateCommunication &&
                permission !== Permission.ScheduleClearRiskReport &&
                permission !== Permission.ChangeLoanPhase &&
                permission !== Permission.EditCollateral &&
                permission !== Permission.ChangeLoanDetails &&
                permission !== Permission.CrudBorrower &&
                permission !== Permission.UpdateBorrower &&
                permission !== Permission.AssignRoles &&
                permission !== Permission.CrudAssociate &&
                permission !== Permission.CrudLabels &&
                permission !== Permission.CrudLoan &&
                permission !== Permission.InviteBorrowersToLoan &&
                permission !== Permission.BorrowerTeamCommunication &&
                permission !== Permission.SeeBorrowersTasks &&
                permission !== Permission.ArchiveLoanEdit &&
                permission !== Permission.LoginAsAUser &&
                permission !== Permission.CrudKeyContacts &&
                permission !== Permission.DeleteCollateral
        ),
    ],
    [Role.BORROWER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.BorrowerTeamPrivateCommunication &&
                permission !== Permission.ScheduleClearRiskReport &&
                permission !== Permission.ChangeLoanPhase &&
                permission !== Permission.ChangeLoanDetails &&
                permission !== Permission.ViewAllLoanHeaderInformation &&
                permission !== Permission.ViewLoanDetails &&
                permission !== Permission.ChangePackageViewType &&
                permission !== Permission.EditCollateral &&
                permission !== Permission.CrudBorrower &&
                permission !== Permission.SeeFormElementAssignment &&
                permission !== Permission.MoveElements &&
                permission !== Permission.SwitchLoanView &&
                permission !== Permission.PinFormElements &&
                permission !== Permission.LendingTeamCommunication &&
                permission !== Permission.BorrowerTeamCommunication &&
                permission !== Permission.AddMeToLoan &&
                permission !== Permission.UpdateBorrower &&
                permission !== Permission.CrudUnderwriter &&
                permission !== Permission.SeeDriversLicense &&
                permission !== Permission.AssignRoles &&
                permission !== Permission.DeleteFormElements &&
                permission !== Permission.CreateFormElements &&
                permission !== Permission.ChangeFormElementPriority &&
                permission !== Permission.EditFormElementTitle &&
                permission !== Permission.CrudAssociate &&
                permission !== Permission.CrudLabels &&
                permission !== Permission.SeeBorrowersTasks &&
                permission !== Permission.AskQuestions &&
                permission !== Permission.Search &&
                permission !== Permission.AddNeedItems &&
                permission !== Permission.GlobalLendingView &&
                permission !== Permission.ArchiveLoanEdit &&
                permission !== Permission.CrudReports &&
                permission !== Permission.LoanPackageCreation &&
                permission !== Permission.LoginAsABorrower &&
                permission !== Permission.CrudLoan &&
                permission !== Permission.InviteBorrowersToLoan &&
                permission !== Permission.AcceptDocuments &&
                permission !== Permission.LoginAsAUser &&
                permission !== Permission.CrudKeyContacts &&
                permission !== Permission.CanSeeSourcesAndUsesButton &&
                permission !== Permission.DeleteCollateral
        ),
    ],
    [Role.LEAD_BORROWER]: [
        ...Object.values(Permission).filter(
            (permission) =>
                permission !== Permission.BorrowerTeamPrivateCommunication &&
                permission !== Permission.ScheduleClearRiskReport &&
                permission !== Permission.ViewLoanDetails &&
                permission !== Permission.ChangePackageViewType &&
                permission !== Permission.LendingTeamCommunication &&
                permission !== Permission.BorrowerTeamCommunication &&
                permission !== Permission.PinFormElements &&
                permission !== Permission.ChangeLoanPhase &&
                permission !== Permission.SeeFormElementAssignment &&
                permission !== Permission.ChangeLoanDetails &&
                permission !== Permission.MoveElements &&
                permission !== Permission.SwitchLoanView &&
                permission !== Permission.ChangeFormElementPriority &&
                permission !== Permission.EditFormElementTitle &&
                permission !== Permission.CreateFormElements &&
                permission !== Permission.DeleteFormElements &&
                permission !== Permission.AddMeToLoan &&
                permission !== Permission.ViewAllLoanHeaderInformation &&
                permission !== Permission.SeeDriversLicense &&
                permission !== Permission.CrudUnderwriter &&
                permission !== Permission.SeeBorrowersTasks &&
                permission !== Permission.CrudBorrower &&
                permission !== Permission.AssignRoles &&
                permission !== Permission.EditCollateral &&
                permission !== Permission.InviteBorrowersToLoan &&
                permission !== Permission.CrudLoan &&
                permission !== Permission.Search &&
                permission !== Permission.AcceptDocuments &&
                permission !== Permission.UpdateBorrower &&
                permission !== Permission.CrudAssociate &&
                permission !== Permission.CrudLabels &&
                permission !== Permission.AskQuestions &&
                permission !== Permission.AddNeedItems &&
                permission !== Permission.CanSeeSourcesAndUsesButton &&
                permission !== Permission.GlobalLendingView &&
                permission !== Permission.ArchiveLoanEdit &&
                permission !== Permission.CrudReports &&
                permission !== Permission.LoanPackageCreation &&
                permission !== Permission.LoginAsABorrower &&
                permission !== Permission.LoginAsAUser &&
                permission !== Permission.CrudKeyContacts &&
                permission !== Permission.DeleteCollateral
        ),
    ],
    [Role.CONTACT_BORROWER]: [],
    [Role.CONTACT_LENDER]: [],
    [Role.NONE]: [],
    [Role.KEY_CONTACT]: [],
}